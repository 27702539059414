<template>
  <div class="joinck">
    <div class="joinck-wrap" v-if="wrapShow">
      <img :src="imgBg" class="bg-img">
      <div class="content-wrap">
        <van-field v-model="userName" placeholder="请输入姓名" maxLength="20" :formatter="nameFormatter" clearable/>
        <van-field v-model="idCard" placeholder="请输入身份证号" maxLength="18" :formatter="IdFormatter" right-icon="photograph" @blur="IDNOBlur" @click-right-icon.stop="rightChooseImg"/>
        <img :src="imgBtn" class="btn-img" @click="applyClick">
      </div>
    </div>
    <van-uploader :preview-image="false"
                    ref="cameraImg"
                    style="display:none;"
                    capture='camera'
                    v-model="fileList"
                    :after-read="afterRead" />
  </div>
</template>

<script>
import common from "../../utils/common";
import {appAction, uwStatisticAction} from "../../utils/util";
import {joinApply, idCardOcrFront, getAgreementInfoByType,getJoinApply, getContractByNo} from '@/utils/api'
export default {
  data() {
    return {
      imgBg: require('@/assets/img/join/bg.png'),
      imgBtn: require('@/assets/img/join/btn.png'),
      userName: null,
      idCard: null,
      fileList: [],
      agreementNo: null,
      wrapShow: false,
      tempToken: ''
    }
  },
  mounted() {
    uwStatisticAction('/joinck','加盟村口')
    this.tempToken = this.$route.query.jumpFrom !=undefined ? this.$route.query.token : common.getItem('wtToken')
    this.togetJoinApply()
  },
  methods:{
    togetJoinApply(){
      getJoinApply({token: this.tempToken}).then(res=>{
        if(res.code === 200){
          if(res.data && res.data.businessCode == 200){
            if(res.data.data.isSign == 1){
              getContractByNo({
                electronContractNo: res.data.data.electronContractNo
              }).then(res=>{
                window.location.href = res.data.data
              })
            }else{
              this.wrapShow = true
            }
          }
        }
      })
    },
    applyClick() {
      if(!this.userName){
        this.$toast('请输入姓名')
        return
      }
      if(!this.idCard){
        this.$toast('请输入身份证号')
        return
      }
      if (!common.IdCardVerify(this.idCard)) {
        this.$toast('站长身份证格式不正确')
        return
      }
      this.protocolClick()
      return
      joinApply({
        token: this.tempToken,
        userName: this.userName,
        idCard: this.idCard,
        agreementNo: 'AGT000'
      }).then(res=>{
         if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            
          }else{
            this.$toast(res.data.businessMessage)
          }
         }
      })
    },
    protocolClick() {
      getAgreementInfoByType({
        token: this.tempToken,
        agreementNo: 'AGT000',
      }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            common.setItem('userNameCK', this.userName)
            common.setItem('idCardCK', this.idCard)
            this.$router.push({path:res.data.data.agreementPath, query:{
              sign: 'sign',
              redirect: 'joinck',
              tokenapp: this.tempToken,
            }})
          }
        }
      })
    },
    IdFormatter (val) {
      return val.replace(/[^\w.v]/ig, "")
    },
    nameFormatter (val) {
      return val.replace(/[^\u4e00-\u9fa5.]/g, '')
    },
    IDNOBlur () {
      if (!this.idCard) return
      if (!common.IdCardVerify(this.idCard)) {
        this.$toast('站长身份证格式不正确')
        return
      }
    },
    rightChooseImg () {
      this.$refs.cameraImg.chooseFile()
    },
    afterRead (file) {
      // 大于15M
      if (file.file.size > 15 * 1024 * 1024) {
        this.$toast('图片太大，请上传小于15M的图片')
        return
      }
      if (!common.imageVerify(file.file.type)) {
        this.fileList.splice(detail.index, 1)
        this.$toast('上传图片必须为png或者jpg格式')
        return
      }
      // 小于100K不进行压缩
      if (file.file.size < 100 * 1024) {
        this.saveFile(file.content)
        return
      }
      this.filename = file.file.name ? file.file.name : "defaultname";
      let reader = new FileReader()
      reader.readAsDataURL(file.file)
      let _this = this
      reader.onload = function (e) {
        let content = file.content //图片的src，base64格式
        let img = new Image()
        img.src = content
        img.onload = function () {
          common.compress(img, (dataURL) => {
            _this.saveFile(dataURL)
          })
        }
      }
    },
    saveFile (dataURL) {
      let params = { fileContent: dataURL }
        idCardOcrFront(params).then(res => {
        if (res.code === 200) {
          if (res.data.businessCode === 200) {
           this.idCard = res.data.idCard
           this.userName = res.data.userName 
          }
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.joinck{
  height: 100%;
  background: #f5f5f5;
  font-size: 0.3rem;
  .joinck-wrap{
    position: relative;
  }
  .bg-img{
    width: 100%;
    height: 100%;
  }
  .btn-img{
    width: 6.6rem;
    height: 1.98rem;
    margin: 0 .45rem;
  }
  .content-wrap{
    position: absolute;
    left: 0;
    top: 13rem;
    width: 100%;
    .van-cell{
      font-size: 0.32rem;
      padding: 0.25rem 0.4rem;
      margin: 0.3rem;
      width: 6.9rem;
      border-radius: 0.16rem;
    }
  }
}
</style>
